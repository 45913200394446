<template >
  <v-dialog v-model="show" @click:outside="onClose" max-width="600px">
    <v-card class="bg" v-if="!review">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card-title>
          <span class="text-h5"
            >{{
              editMode
                ? "Actualizar"
                : type === "STANDARD"
                ? "Generar"
                : "Crear"
            }}
            {{
              type === "STANDARD" ? " Link de pago" : " Link de Producto"
            }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation class="p-2">
            <small class="font-semibold">
              <span class="required">*</span>Indica campo requerido</small
            >
            <v-row>
              <v-col cols="12" md="8">
                <validation-provider
                  v-slot="{ errors }"
                  name="monto" 
                  rules="custom-required|custom-max|custom-min"
                >
                  <label for="Monto" class="text-sm"
                    >Monto (RD$)<span class="required">*</span></label
                  >
                  <br />
                  <input
                    placeholder="RD$"
                    v-model="myLink.amount"
                    type="number"
                    id="Monto"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col v-if="type === 'PRODUCT'" cols="12" md="4">
                <v-avatar
                  @click="$refs.file.$refs.input.click()"
                  class="pl-3 mx-auto"
                  size="100"
                  tile
                >
                  <v-img
                    class="img"
                    :src="
                      myLink.image ||
                      'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc='
                    "
                  ></v-img>
                </v-avatar>
                <v-btn
                  class="btn"
                  @click="$refs.file.$refs.input.click()"
                  elevation="1"
                  icon
                >
                  <v-icon>mdi-image-plus</v-icon></v-btn
                >
              </v-col>
              <template v-if="type === 'PRODUCT'">
                <v-file-input
                  @change="onFileChange"
                  style="display: none"
                  ref="file"
                  id="imageInput"
                  :rules="logoRules"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  label="Avatar"
                ></v-file-input>
                <v-col cols="12" md="12" sm="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="nombre del producto"
                    rules="custom-required"
                  >
                    <label for="Nombre del producto" class="text-sm"
                      >Nombre del producto
                      <span class="required">*</span></label
                    >
                    <br />
                    <input
                      v-model="myLink.description"
                      type="text"
                      :class="inputClasses"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="limite de Uso"
                    rules="custom-min"
                  >
                    <label for="Limite de Uso (Veces)" class="text-sm"
                      >Límite de Uso (Veces)</label
                    >
                    <br />
                    <input
                      v-model="myLink.usageLimit"
                      type="number"
                      id="Limite de Uso (Veces)"
                      :class="inputClasses"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                  <span class="text text-justify"
                    >Usa este campo si tienes un limite de venta (stock) para
                    este producto o servicio.</span
                  >
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Expiración"
                      >
                        <label for="Expiración" class="text-sm"
                          >Expiración</label
                        >
                        <v-icon class="pl-1">mdi-calendar</v-icon>
                        <input
                          prepend-icon="mdi-calendar"
                          v-model="myLink.expiresIn"
                          type="text"
                          id="Expiración"
                          :class="inputClasses"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                        <span style="color: red">{{ errors[0] }}</span>
                      </validation-provider>
                      <span class="text text-justify">
                        Puedes especificar una fecha de expiracion para que el
                        link deje de estar activo.
                      </span>
                    </template>
                    <v-date-picker
                      v-model="myLink.expiresIn"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12">
                  <h4 class="font-semibold pb-3 pl-2">
                    Información del cliente (Opcional)
                  </h4>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre del cliente"
                  >
                    <label for="Nombre del cliente" class="text-sm"
                      >Nombre del cliente</label
                    >
                    <br />
                    <input
                      v-model="myLink.payer.name"
                      type="text"
                      id="Nombre del cliente"
                      :class="inputClasses"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="custom-email"
                  >
                    <label for="Email" class="text-sm">Email</label>
                    <br />
                    <input
                      v-model="myLink.payer.email"
                      type="text"
                      id="email"
                      :class="inputClasses"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider v-slot="{ errors }" name="Telefono">
                    <label for="Telefono" class="text-sm">Telefono</label>
                    <br />
                    <input
                      v-model="myLink.payer.phone"
                      type="tel"
                      id="Telefono"
                      :class="inputClasses"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose"> Cerrar </v-btn>
          <v-btn :disabled="invalid" color="blue darken-1" text @click="save">
            {{
              editMode
                ? "Actualizar"
                : type === "STANDARD"
                ? "Generar"
                : "Crear"
            }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="text-h5">Link Review </span>
      </v-card-title>
      <v-card-text>
        <div class="font-weight-bold ml-8 mb-2">Review</div>

        <v-timeline align-top dense>
          <v-timeline-item v-if="myLink.description" color="green" small>
            <div>
              <div class="font-weight-normal">
                <strong>{{ myLink.description }}</strong>
              </div>
              <div>Descripción del Producto</div>
            </div>
          </v-timeline-item>
          <v-timeline-item color="green" small>
            <div>
              <div class="font-weight-normal">
                <strong>{{ myLink.amount | money }}</strong>
              </div>
              <div>Monto Total</div>
            </div>
          </v-timeline-item>
          <v-timeline-item color="green" small>
            <div>
              <div class="font-weight-normal">
                <strong>5.95%</strong>
              </div>
              <div>Comision</div>
            </div>
          </v-timeline-item>
          <v-timeline-item color="green" small>
            <div>
              <div class="font-weight-normal">
                <strong>{{
                  (myLink.amount - myLink.amount * (5.95 / 100)) | money
                }}</strong>
              </div>
              <div>Usted Recibira</div>
            </div>
          </v-timeline-item>
          <v-timeline-item
            v-clipboard:copy="myLink.url"
            v-clipboard:success="onCopy"
            color="blue"
            small
          >
            <v-btn class="ml-5" icon depressed>
              <v-icon color="blue" class="p-2 icon">mdi-content-copy</v-icon>
              <span class="ml-2">Copiar Link</span>
            </v-btn>
            <!-- <div>
              <div class="font-weight-normal">
                <strong>{{ myLink.url }}</strong>
              </div>
              <div>Link Url (click to copy)</div>
            </div> -->
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// setInteractionMode("eager");
import { createFloat, renewSession } from "@/utils";
import dot from "dot-object";
import { required, max, min, email } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

extend("custom-required", {
  ...required,
  message: "El {_field_} no puede estar vacio",
});

extend("custom-max", {
  ...max,
  validate: (value) => {
    if (value <= 20000) {
      return true;
    }
  },
  message: "El {_field_} no puede ser mayor de 20000",
});

extend("custom-min", {
  ...min,
  validate: (value) => {
    if (value > 0) {
      return true;
    }
  },
  message: "El {_field_} no puede ser menor de 0",
});

extend("custom-email", {
  ...min,
  message: "El {_field_} debe ser valido",
});

export default {
  data: () => ({
    myLink: {
      payer: {},
    },
    inputClasses:
      "shadow appearance-none border rounded w-full py-3 px-2 text-gray-700 mt-3 leading-tight focus:outline-none focus:shadow-outline",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    valid: false,
    logoRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    appUrl: process.env.VUE_APP_YOYO_URL,
    review: false,
  }),
  props: {
    show: Boolean,
    editMode: Boolean,
    link: {},
    type: String,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    link() {
      if (this.editMode) {
        this.myLink = {
          _id: this.link._id,
          amount: parseInt(this.link.amount),
          image: this.link.image,
          type: this.link.type,
          ...(this.link.expiresIn
            ? {
                expiresIn: new Date(this.link.expiresIn)
                  .toISOString()
                  .substr(0, 10),
              }
            : {}),
          ...(this.link.nonExpiring
            ? { nonExpiring: this.link.nonExpiring }
            : {}),
          ...(this.link.usageLimit ? { usageLimit: this.link.usageLimit } : {}),
          ...(this.link.description
            ? { description: this.link.description }
            : {}),
          payer: {
            ...(this.link.payer && this.link.payer.name
              ? { name: this.link.payer.name }
              : {}),
            ...(this.link.payer && this.link.payer.email
              ? { email: this.link.payer.email }
              : {}),
            ...(this.link.payer && this.link.payer.phone
              ? { phone: this.link.payer.phone }
              : {}),
          },
        };
      } else this.myLink = { payer: {} };
    },
  },
  methods: {
    onFileChange() {
      const imagefile = document.querySelector("#imageInput");
      const [file] = imagefile.files;
      if (file) {
        this.myLink = { ...this.myLink, image: URL.createObjectURL(file) };
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        if (this.type === "PRODUCT") {
          const imagefile = document.querySelector("#imageInput");
          if (imagefile) formData.append("image", imagefile.files[0]);
        }

        const linkInfo = {
          amount: parseInt(this.myLink.amount),
          type: this.type,
          ...(this.myLink.expiresIn
            ? { expiresIn: this.myLink.expiresIn }
            : {}),
          ...(this.myLink.nonExpiring
            ? { nonExpiring: this.myLink.nonExpiring }
            : {}),
          ...(this.myLink.usageLimit
            ? { usageLimit: this.myLink.usageLimit }
            : {}),
          ...(this.myLink.description
            ? { description: this.myLink.description }
            : {}),
        };

        const payerInfo = {
          ...(this.myLink.payer.name ||
          this.myLink.payer.email ||
          this.myLink.payer.phone
            ? {
                payer: {
                  ...(this.myLink.payer.name
                    ? { name: this.myLink.payer.name }
                    : {}),
                  ...(this.myLink.payer.email
                    ? { email: this.myLink.payer.email }
                    : {}),
                  ...(this.myLink.payer.phone
                    ? { phone: this.myLink.payer.phone }
                    : {}),
                },
              }
            : {}),
        };

        const dotifiedBody = {
          ...dot.dot(linkInfo),
          ...dot.dot(payerInfo),
        };

        for (const key of Object.keys(dotifiedBody)) {
          //console.log(key);
          formData.append(key, dotifiedBody[key]);
        }

        const resp = await fetch(
          `${process.env.VUE_APP_API_URL}/transactions/${
            this.editMode ? "product-links/" + this.link._id : "generate"
          }`,
          {
            method: this.editMode ? "PUT" : "POST",
            headers: {
              "x-yoyo-key": localStorage.getItem("session"),
            },
            body: formData,
          }
        );

        const { ok, data, errors, errorMessage } = await resp.json();
        if (ok) {
          if (!this.editMode) this.myLink.url = data;
          else {
            this.myLink = {
              ...data,
              ...(data.expiresIn
                ? {
                    expiresIn: new Date(data.expiresIn)
                      .toISOString()
                      .substr(0, 10),
                  }
                : {}),
            };
          }

          this.review = true;

          if (this.editMode) this.$emit("edited", data);
          else this.$emit("added", this.myLink);
          renewSession(resp);
        }
        if (!ok) {
          if (errorMessage) this.error = errorMessage;
          else if (errors) {
            this.error = errors[0].msg;
          } else {
            this.error = resp.statusText;
          }
        }
      }
    },
    onClose() {
      this.$emit("close:form", false);
      this.review = false;
      this.myLink = {
        type: this.type,
        payer: {},
      };
      const imagefile = document.querySelector("#imageInput");
      imagefile.value = "";
    },

    onCopy: function (e) {
      this.$emit(
        "float:created",
        createFloat({ text: "Link Copiado!", duration: 2000 })
      );
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}

.btn {
  background-color: white !important;
  position: relative;
  right: 25px;
  top: 37px;
  border: #f78f6e 2px solid;
}

.img {
  border-radius: 7px !important;
}

.text {
  font-size: 12px !important;
  padding: 1px;
}
</style>