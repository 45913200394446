





















import Vue from "vue";
import Vuetify from "vuetify";
import VueClipboard from "vue-clipboard2";

import Header from "@/components/Header.vue";
import Float from "@/components/Float.vue";
// import "tailwindcss/tailwind.css";
import "@/assets/tailwind.css";
import { formatCurrency, renewSession } from "./utils";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Float,
  },
  data: () => ({
    link: undefined,
    float: { text: "", duration: 2000 },
    showHeader: true,
    authenticated: false,
    merchant: {},
  }),
  watch: {
    $route(to, from) {
      //console.log(from);
      this.showHeader = to.path === "/authenticate" ? false : true;

      if (!this.$route.meta?.guest) {
        this.getMerchantProfile();
      }
    },
  },
  mounted() {
    if (this.$route.meta?.guest) {
      this.showHeader = false;
    } else {
      this.getMerchantProfile();
    }
  },
  methods: {
    onFloatCreated(float: any) {
      this.float = float;
    },
    onLinkAdded(l: any) {
      //console.log("app:added");
      this.link = l;
    },
    onMerchantUpdate(data: any) {
      this.merchant = data;
    },

    async getMerchantProfile() {
      //console.log(process.env.VUE_APP_API_URL);
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/auth/profile`, {
        headers: {
          "Content-Type": "application/json",
          "x-yoyo-key": localStorage.getItem("session") || "",
        },
      });

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.merchant = data;
        //console.log('@app-getting-merchant', this.merchant)
        renewSession(resp);
      }
      let error = "";
      if (!ok) {
        if (errorMessage) error = errorMessage;
        else if (errors) {
          error = errors[0].msg;
        } else {
          error = resp.statusText;
        }
        //console.log(error);
      }
    },
  },
});

Vue.filter("money", formatCurrency);

Vue.use(Vuetify);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
