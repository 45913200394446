<template>
  <v-alert
    v-if="show"
    transition="fade-transition"
    :type="date.type || 'success'"
    >{{ date.text }}</v-alert
  >
</template>
<script>
export default {
  data: () => ({
    show: false,
  }),
  props: {
    date: Object,
  },
  watch: {
    date() {
      //console.log("watching data", this.date);
      this.show = true;
      setTimeout(() => (this.show = false), this.date.duration);
    },
  },
  mounted() {},
  methods: {},
};
</script>