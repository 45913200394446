export function renewSession(resp: Response) {
  const apiHeader = resp.headers.get(process.env.VUE_APP_API_HEADER);
  if (apiHeader) {
    localStorage.setItem("session", apiHeader);
  }
}

export function createFloat(opts: { text: string; type: string; duration: number }) {
  const float: any = {};
  if (opts.text) float.text = opts.text;
  if (opts.type) float.type = opts.type;
  if (opts.duration) float.duration = opts.duration;
  return float;
}

export function formatCurrency(value: number) {
  const formatter = new Intl.NumberFormat("es-DO", {
    style: "currency",
    currency: "DOP",
  });
  return formatter.format(value);
}
